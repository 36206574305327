import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import fetch from 'cross-fetch'

export const graphqlClient = () => {
  const httpLink = createHttpLink({
    fetch,
    uri: process.env.GATSBY_CONTENTFUL_GRAPHQL_ENDPOINT,
  })
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${process.env.GATSBY_CONTENTFUL_PREVIEW_TOKEN}`,
      },
    }
  })

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  })

  return client
}
